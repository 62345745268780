.work-container {
  width: 100%;
  background-color: var(--background-black);

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
  box-shadow: -2px -10px 3px -6px rgba(0, 0, 0, 0.75) inset;
  -webkit-box-shadow: -2px -10px 3px -6px rgba(0, 0, 0, 0.75) inset;
  -moz-box-shadow: -2px -10px 3px -6px rgba(0, 0, 0, 0.75) inset;
}
.work {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.work-img {
  height: 100%;
  width: 50%;
}
.work-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  box-shadow: 15px 14px 3px -6px rgba(0, 0, 0, 0.74);
  -webkit-box-shadow: 15px 14px 3px -6px rgba(0, 0, 0, 0.74);
  -moz-box-shadow: 15px 14px 3px -6px rgba(0, 0, 0, 0.74);
}
.work-text {
  width: 50%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.work-text h2 {
  font-size: 1.9rem;
  font-family: monospace;
  font-weight: bold;
  font-style: italic;
  text-shadow: 1px 1px #000;
}
.work-text p {
  text-align: justify;
  width: 90%;
  color: rgba(255, 255, 255, 0.7);
}
.work-text a {
  text-decoration: none;
  color: var(--button-background);
}

@media (max-width: 700px) {
  .work {
    flex-direction: column;
    gap: 1rem;
  }
}
@media (max-width: 380px) {
  .work {
    width: 100%;
  }
  .work-img {
    width: 80%;
  }
  .work-text {
    width: 80%;
  }
}
