header {
  width: 100%;
  height: 7rem;
  background: linear-gradient(
    180deg,
    #de2910 35%,
    var(--background-black) 100%
  );
  display: flex;
  align-items: center;
  justify-content: center;
}
.header-container {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header-logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.logo {
  width: 200px;
}
.logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.header-logo p {
  width: auto;
  font-size: 0.8rem;
  font-family: monospace;
  font-style: italic;
  color: #fdd02e;
  text-shadow: 1px 1px #000;
}
.social-media-header li {
  box-shadow: 3px 11px 1px -7px rgba(0, 0, 0, 0.74);
  -webkit-box-shadow: 3px 11px 1px -7px rgba(0, 0, 0, 0.74);
  -moz-box-shadow: 3px 11px 1px -7px rgba(0, 0, 0, 0.74);
}
.social-media-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.social-media-header li {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
  font-size: 2rem;
  width: 50px;
  height: 50px;
  background-color: #d25b0e;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  cursor: pointer;
  color: #ddd;
}
.social-media-header li a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.skype a {
  color: #00b2ec;
  background: #ddd;

  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.skype:hover a {
  background: var(--button-background);
}
.facebook a {
  color: #4ac0e7;
  background: #ddd;

  border-radius: 50%;
}
.facebook:hover a {
  background: var(--button-background);
}
.email a {
  color: #db4e41;
  background: #ddd;

  border-radius: 50%;
}
.email:hover a {
  background: var(--button-background);
}

@media (max-width: 650px) {
  header {
    height: auto;
    padding-bottom: 0.5rem;
  }
  .header-container {
    flex-direction: column;
    height: auto;
  }
}
