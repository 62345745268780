.slider-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--background-black);
  padding-top: 1rem;
  box-shadow: -2px -10px 3px -6px rgba(0, 0, 0, 0.75) inset;
  -webkit-box-shadow: -2px -10px 3px -6px rgba(0, 0, 0, 0.75) inset;
  -moz-box-shadow: -2px -10px 3px -6px rgba(0, 0, 0, 0.75) inset;
  padding-bottom: 1rem;
}
.slider {
  width: 80%;
}

.swiper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.swiper-slide img {
  display: block;
  width: 70%;
  height: 70%;
  object-fit: cover;
}
.swiper-button-next,
.swiper-button-prev {
  color: #fff;
}
@media (max-width: 380px) {
  .slider {
    width: 90%;
    padding-bottom: 0.8rem;
  }
}
