nav {
  width: 100%;
  background: var(--background-black);
  display: flex;
  align-items: center;
  justify-content: center;
}
a {
  color: var(--headline-white);
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  width: 100vw;
}
.nav-list {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.nav-list-item {
  width: 100%;
  font-size: 1rem;
  cursor: pointer;
  font-weight: 600;
  text-align: center;
  transition: all 1s ease-in-out;
  -webkit-transition: all 1s ease-in-out;
  -moz-transition: all 1s ease-in-out;
  -ms-transition: all 1s ease-in-out;
  -o-transition: all 1s ease-in-out;

  position: relative;
  display: inline-block;
  padding: 0.5rem 0;
}
.nav-list-item::after {
  display: inline-block;
  content: '';
  height: 2px;
  background-color: rgb(238, 238, 115);
  position: absolute;
  z-index: -1;
  right: 0;
  width: 0;
  bottom: 0;
  height: 4px;
  transition: width 1s ease-in-out;
  -webkit-transition: width 1s ease-in-out;
  -moz-transition: width 1s ease-in-out;
  -ms-transition: width 1s ease-in-out;
  -o-transition: width 1s ease-in-out;
}
.nav-list-item:hover:after,
.nav-list-item:focus:after,
.nav-list-item:active:after {
  left: 0;
  right: auto;
  width: 100%;
}
.nav-list-item::before {
  display: inline-block;
  content: '';
  height: 2px;
  background-color: rgb(238, 238, 115);
  position: absolute;
  z-index: -1;
  left: 0;
  width: 0;
  top: 0;
  height: 4px;
  transition: width 1s ease-in-out;
  -webkit-transition: width 1s ease-in-out;
  -moz-transition: width 1s ease-in-out;
  -ms-transition: width 1s ease-in-out;
  -o-transition: width 1s ease-in-out;
}
.nav-list-item:hover:before,
.nav-list-item:focus:before,
.nav-list-item:active:before {
  right: 0;
  left: auto;
  width: 100%;
}

.nav-list-item:hover {
  background-color: var(--button-background);
  border-radius: 1rem;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  -ms-border-radius: 1rem;
  -o-border-radius: 1rem;
}
.nav-list-item:hover a {
  color: #333;
}
.dropdown {
  display: none;
  position: absolute;
  overflow: auto;
  border-radius: 5px;
  box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.4);
  z-index: 999999;
  top: 40px;
  width: 100%;
}
.nav-list-item:hover .dropdown {
  display: block;
}
.nested-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 1rem;
  background-color: var(--button-background);
}

.nested-list li {
  font-size: 1rem;
  width: 100%;
  padding: 0.6rem 0.5rem;
  transition: all 1s ease-in-out;
  -webkit-transition: all 1s;
  -moz-transition: all 1s;
  -ms-transition: all 1s;
  -o-transition: all 1s;
  background-color: var(--button-background);
}
.nested-list li a {
  color: var(--headline-text);
}

.nested-list li:last-child {
  border-top: 1px solid #eee;
}

.nested-list li:hover a {
  color: #555;
}

@media (max-width: 760px) {
  nav ul li {
    font-size: 1.1rem;
  }
}
@media (max-width: 600px) {
  nav ul {
    width: 100%;
  }
}
@media (max-width: 480px) {
  nav {
    height: auto;
  }
  nav ul {
    flex-direction: column;
  }
}
