.about-container {
  width: 100%;
  background-color: var(--background-black);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
  box-shadow: -2px -10px 3px -6px rgba(0, 0, 0, 0.75) inset;
  -webkit-box-shadow: -2px -10px 3px -6px rgba(0, 0, 0, 0.75) inset;
  -moz-box-shadow: -2px -10px 3px -6px rgba(0, 0, 0, 0.75) inset;
}
.about {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.about-img {
  width: 100%;
  height: 100%;
  flex: 1;
  margin-right: 1.5rem;
}
.about-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  box-shadow: -15px 14px 3px -6px rgba(0, 0, 0, 0.74);
  -webkit-box-shadow: -15px 14px 3px -6px rgba(0, 0, 0, 0.74);
  -moz-box-shadow: -15px 14px 3px -6px rgba(0, 0, 0, 0.74);
}
.about-text {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.about-text h2 {
  font-size: 1.9rem;
  font-family: monospace;
  font-weight: bold;
  font-style: italic;
  margin-bottom: 2rem;
  text-shadow: 1px 1px #000;
}
.about-text p {
  text-align: justify;
  width: 90%;
  color: rgba(255, 255, 255, 0.7);
}

@media (max-width: 700px) {
  .about {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
}

@media (max-width: 380px) {
  .about {
    width: 100%;
  }
  .about-img {
    width: 80%;
  }
  .about-text {
    width: 80%;
  }
}
