.contactSection {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: var(--background-black);
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  box-shadow: -2px 10px 3px -6px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: -2px 10px 3px -6px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -2px 10px 3px -6px rgba(0, 0, 0, 0.75);
}
.contactSection .formTitle {
  margin-bottom: 2rem;
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  text-shadow: 1px 1px #000;
}
.contactSection form {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.contactSection form .formContainer {
  width: 100%;
  margin-left: 2rem;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.contactSection form .formContainer input {
  width: 35%;
  margin-bottom: 0.5rem;
  padding: 0.6rem;
  outline: none;
  border: 1px solid #ccc;
  border-top-left-radius: 8px;
  border-bottom-right-radius: 8px;
  transition: all 0.5s ease-in-out;
}
.contactSection form .formContainer input:focus {
  border: 1px solid #0f0;
}
.contactSection form .formContainer input:first-child {
  margin-right: 2rem;
}
.contactSection form .formContainer .subject {
  width: 72.8%;
  margin-left: 1.8rem;
}
.contactSection form .formContainer textarea {
  border-top-left-radius: 8px;
  border-bottom-right-radius: 8px;
  margin-bottom: 0.5rem;
  width: 72.9%;
  padding: 0.6rem;
  outline: none;
  border: 1px solid #ccc;
  resize: none;
  font-size: 1rem;
}
.contactSection form .formContainer textarea:focus {
  border: 1px solid #0f0;
}
.contactSection form .formContainer input[type='submit'] {
  color: #fff;
  width: 20%;
  outline: none;
  border: 1px solid #ddd;
  background-color: #000;
  font-size: 1.3rem;
  font-weight: bold;
  font-family: 'Courier New', Courier, monospace;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}
.contactSection form .formContainer input[type='submit']:hover {
  background-color: var(--button-background);
  color: var(--sub-heading-text);
}
@media (max-width: 500px) {
  .contactSection .formTitle {
    font-size: 1.6rem;
  }
  .contactSection .formContainer:first-child {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .contactSection .formContainer:first-child input {
    width: 72%;
  }
  .contactSection .formContainer:first-child input:first-child {
    margin-right: 0;
  }
}
