.footer-container {
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 2rem 0;
  background: linear-gradient(180deg, var(--background-black), #de2910 150%);
}
.footer {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.footer-lists {
  flex: 1;
  display: flex;
  align-items: baseline;
  justify-content: space-evenly;
  width: 100%;
}
.list-nav {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.list-nav li {
  font-size: 1rem;
  width: auto;
  margin-bottom: 0.5rem;
  padding: 0.3rem 0.6rem;
}
.list-nav li a {
  font-weight: 600;
}
.list-nav li:hover {
  transform: scale(1.5);
  -webkit-transform: scale(1.5);
  -moz-transform: scale(1.5);
  -ms-transform: scale(1.5);
  -o-transform: scale(1.5);
  transition: all 0.9s ease-in-out;
  -webkit-transition: all 0.9s ease-in-out;
  -moz-transition: all 0.9s ease-in-out;
  -ms-transition: all 0.9s ease-in-out;
  -o-transition: all 0.9s ease-in-out;
  background-color: var(--button-background);
  border-radius: 1rem;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  -ms-border-radius: 1rem;
  -o-border-radius: 1rem;
}

.service-list {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;

  justify-content: flex-start;
  width: 100%;
}
.service-list li {
  font-size: 1rem;
  margin-bottom: 0.5rem;
  font-size: 1.1rem;
  padding: 0.1rem 0.6rem;
}
.service-list li a {
  font-weight: 600;
}
.service-list li:not(:first-child):hover {
  transform: scale(1.5);
  -webkit-transform: scale(1.5);
  -moz-transform: scale(1.5);
  -ms-transform: scale(1.5);
  -o-transform: scale(1.5);
  transition: all 0.9s ease-in-out;
  -webkit-transition: all 0.9s ease-in-out;
  -moz-transition: all 0.9s ease-in-out;
  -ms-transition: all 0.9s ease-in-out;
  -o-transition: all 0.9s ease-in-out;
  background-color: var(--button-background);
  border-radius: 1rem;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  -ms-border-radius: 1rem;
  -o-border-radius: 1rem;
}
.service-list li:first-child {
  margin-bottom: 1rem;
  font-size: 1.3rem;
  font-weight: 600;
}

.footer-subscribe {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.footer-logo {
  width: 180px;
  height: 100px;
}
.footer-logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.subscribe {
  width: 75%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}
.subscribe h3 {
  font-size: 1rem;
  line-height: 1.8rem;

  font-weight: bold;
  color: #fdd02e;
  text-shadow: 1px 1px #000;
}
.subscribe-form {
  margin-top: 1rem;
  display: flex;

  width: 100%;
}
.subscribe-form input {
  flex: 1;
  width: 15rem;
  padding: 0.5rem 1rem;
  outline: none;
  border: 1px solid #333;
  border-radius: 0.8rem;
  -webkit-border-radius: 0.8rem;
  -moz-border-radius: 0.8rem;
  -ms-border-radius: 0.8rem;
  -o-border-radius: 0.8rem;
  width: 100%;
}

.subscribe-form .button {
  outline: none;
  border: none;
  cursor: pointer;
  background-color: tomato;
  color: #fff;
  padding: 0.3rem 0.5rem;
  font-size: 1rem;
  border-left: none;
  margin-top: 0.5rem;
  border-radius: 0.8rem;
  -webkit-border-radius: 0.8rem;
  -moz-border-radius: 0.8rem;
  -ms-border-radius: 0.8rem;
  -o-border-radius: 0.8rem;
}

.qrQode {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.qrQode h3 {
  font-size: 1.2rem;
  color: var(--button-background);
  text-shadow: 1px 1px #000;
}
.qrQode div {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
}
.qrQode div img {
  width: 150px;
  height: 150px;
  object-fit: cover;
}
.social {
  width: 55%;
}
.social-media-footer li {
  box-shadow: 3px 11px 1px -7px rgba(0, 0, 0, 0.74);
  -webkit-box-shadow: 3px 11px 1px -7px rgba(0, 0, 0, 0.74);
  -moz-box-shadow: 3px 11px 1px -7px rgba(0, 0, 0, 0.74);
}
.social-media-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.social-media-footer li {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  width: 50px;
  height: 50px;
  background-color: #d25b0e;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  cursor: pointer;
  color: #ddd;
}
.social-media-footer li a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

@media (max-width: 800px) {
  .footer {
    flex-direction: column;
  }
  .footer-lists {
    flex-direction: column;
  }
  .list-nav {
    flex-direction: row;
  }
  .list-nav li {
    font-size: 1rem;
    margin-right: 1rem;
  }
  .service-list {
    flex-direction: row;
    font-size: 1rem;
  }
  .service-list li,
  .service-list li:first-child {
    margin-bottom: 0;
    font-size: 1rem;
  }
  .subscribe-form {
    width: 80%;
  }
}
@media (max-width: 580px) {
  .footer {
    width: 100%;
  }
  .footer-subscribe {
    margin-bottom: 1rem;
  }
  .subscribe h3 {
    font-size: 0.9rem;
  }
  .footer-lists {
    flex-direction: row;
  }
  .list-nav,
  .service-list {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .subscribe {
    width: 80%;
  }
}
@media (max-width: 380px) {
  .subscribe-form {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .subscribe-form input {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    margin-bottom: 0.5rem;
  }
  .subscribe-form .button {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    padding: 0.5rem;
  }
  .qrQode h3 {
    font-size: 0.9rem;
  }
}
